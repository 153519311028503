import { notification, style } from './shared'

export function setupCard({ stripeKey, clientSecret, returnUrl }) {
  return {
    // mixin notification
    ... notification,

    cardErrors: '',
    stripe: null,
    card: null,
    stripeKey,
    clientSecret,
    returnUrl,

    setupStripe() {
      this.stripe = Stripe(this.stripeKey)
      this.setupCardElement()
    },

    setupCardElement() {
      const elements = this.stripe.elements()
      // Create an instance of the card Element.
      this.card = elements.create('card', { style })

      // Add an instance of the card Element into the `card-element` <div>.
      this.card.mount('#card-element')
      this.card.addEventListener('change', this.onCardChange.bind(this))
    },

    onCardChange({ error }) {
      this.cardErrors = error ? error.message : ''
    },

    onFormSubmit() {
      this.clearNotification()

      $('#loadingModal').modal({
        keyboard: false,
      }).modal('show')

      this.stripe
        .confirmCardSetup(this.clientSecret, {
          payment_method: {
            card: this.card,
          },
        })
        .then((response) => {
          if (response.error) {
            $('#loadingModal').modal('hide')
            this.error(response.error.message)
          } else if (
            response.setupIntent &&
            response.setupIntent.status === 'succeeded'
          ) {
            this.success('Card setup is successful')
            setTimeout(() => {
              if (this.returnUrl) {
                window.parent.location.replace(this.returnUrl)
              } else {
                window.location.reload()
              }
            }, 1000)
          } else {
            $('#loadingModal').modal('hide')
            this.error('Something wrong, please contact FariaPay staff.')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
  }
}
