import { notification, style } from './shared'

export function checkout({ stripeKey, clientSecret, pmid, returnUrl, cancelUrl, showBody }) {
  return {
    // mixin notification
    ... notification,

    showNewCard: !pmid,
    cardErrors: '',
    stripe: null,
    card: null,
    saveCard: true,
    stripeKey,
    clientSecret,
    pmid,
    returnUrl,
    cancelUrl,
    showBody,

    setupStripe() {
      this.stripe = Stripe(this.stripeKey)
      this.showNewCard && this.setupCardElement()
    },

    showNewCardElement() {
      this.showNewCard = true
      if (!this.card) {
        this.setupCardElement()
      }
    },

    setupCardElement() {
      const elements = this.stripe.elements()
      // Create an instance of the card Element.
      this.card = elements.create('card', { style })

      // Add an instance of the card Element into the `card-element` <div>.
      this.card.mount('#card-element')
      this.card.addEventListener('change', this.onCardChange.bind(this))
    },

    onCardChange({ error }) {
      this.cardErrors = error ? error.message : ''
    },

    onFormSubmit() {
      const payment_method = this.showNewCard ? { card: this.card } : this.pmid
      const setup_future_usage = this.saveCard == '1' ? 'on_session' : null

      this.clearNotification()

      $('#loadingModal').modal({
        keyboard: false,
      }).modal('show')

      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method,
          setup_future_usage,
        })
        .then((response) => {
          if (response.error) {
            $('#loadingModal').modal('hide')
            this.error(response.error.message)
          } else if (
            response.paymentIntent &&
            response.paymentIntent.status === 'succeeded'
          ) {
            this.success('Payment is successful')
            setTimeout(() => {
              window.parent.location.replace(this.returnUrl)
            }, 1000)
          } else {
            $('#loadingModal').modal('hide')
            this.error('Something wrong, please contact FariaPay staff.')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    onCancel() {
      window.parent.location.replace(this.cancelUrl)
    },

  }
}
